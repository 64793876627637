<template>
  <kudo-modal @close-clicked="close()">
    <template #header>
      <div class="flex gap-x-4">
        <div class="flex items-center gap-4">
          <div class="text-red fas fa-exclamation-circle" />
          <span class="text-xl">{{ title }}</span>
        </div>
      </div>
    </template>
    <div class="flex flex-col gap-y-4">
      <p class="text-sm">
        {{ message }}
      </p>
      <kudo-button
        v-if="showOk"
        class="self-end"
        @click="close()"
      >
        Ok
      </kudo-button>
    </div>
  </kudo-modal>
</template>

<script>
import { KudoModal, KudoButton } from '@openly-engineering/kudo'

export default {
  name: 'ModalError',
  components: {
    KudoModal,
    KudoButton,
  },
  props: {
    onCloseCallback: {
      type: Function,
      required: false,
      default: null,
    },
    title: {
      type: String,
      required: false,
      default: 'Something went wrong',
    },
    message: {
      type: String,
      required: false,
      default: 'Please try that again.  If you continue to have trouble, please contact our Agent Experience team.',
    },
    showOk: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    close() {
      this.onCloseCallback?.()

      // IMPORTANT: Any modal that should be used in the dynamic component for error handling MUST
      // emit this event in order to have the modal close correctly.
      this.$emit('done')
    },
  },
}
</script>
